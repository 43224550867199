<script setup lang="ts">
const theme = inject<'light' | 'dark'>('theme');

const errorsState = useErrorsStore();
const { errors, hasErrors } = storeToRefs(errorsState);

function removeErrors() {
  errorsState.removeErrors();
}
</script>

<template>
  <div v-if="hasErrors" class="backend-error">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="error-box">
            <Button
              class="button--icon"
              variant="outlined"
              :title="$t('server-validations.actions.close')"
              :theme="theme"
              @click="removeErrors"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="m1 1 16 16m0-16L1 17"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                />
              </svg>
            </Button>

            <div class="errors">
              <div v-for="error in errors" :key="error" class="errors__error">
                {{ $te(error) ? $t(error) : error }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
html:has(.dialog--opened) .backend-error {
  top: 0;
}
</style>

<style lang="scss" scoped>
.backend-error {
  z-index: 10001;
  position: fixed;
  top: var(--menu-clearfix-height);
  right: 0;
  left: 0;
}

.container {
  padding-top: 1rem;
}

.error-box {
  display: grid;
  grid-template-columns: calc(1rem + 0.25rem) 1fr;
  place-items: start;
  grid-gap: 1rem;

  padding: 0.75rem;

  background-color: var(--red-neon-basic);
  border-radius: 0.375rem;
}

.button {
  height: calc(var(--font-size-base) * 1.5);
}

.errors__error {
  display: flex;
  flex-direction: column;

  color: var(--text-primary);
}
</style>
